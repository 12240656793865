import * as React from 'react'
import { ReactNode } from 'react'
import AnimateHeight from 'react-animate-height'
import { amClickedFAQ } from 'events/amplitude'
import styles from './SlideDown.module.scss'

export interface SlideDownProps {
	// textPosition: 'left' | 'center' | 'right'
	children?: React.ReactNode
	top: React.ReactNode
	arrow?: boolean
	onOpenClick?: () => void
	onCloseClick?: () => void
	topWidth?: string[] | number[]
	animationDuration?: number
	className: string
	role?: string
	chevronSrc?: string
	inverted?: boolean
}

interface State {
	open: boolean
	id: string
}

export default class SlideDown extends React.Component<SlideDownProps, State> {
	static defaultProps: Partial<SlideDownProps> = {
		arrow: false,
		// textPosition: 'left',
		// color: '#fff'
	}

	constructor(props) {
		super(props)
		this.state = {
			open: false,
			id: 'slideDown' + (Math.random() * 100).toString(),
		}
	}

	handleClick() {
		this.setState({ open: !this.state.open })
		const has_open_click = this.props.onOpenClick !== undefined
		const has_close_click = this.props.onCloseClick !== undefined
		const is_closed = !this.state.open
		const is_open = this.state.open

		const getNodeText = (node: ReactNode) => {
			if (['string', 'number'].includes(typeof node)) return node
			if (node instanceof Array) return node.map(getNodeText).join('')
			if (typeof node === 'object' && node) return getNodeText(node.props.children)
		}

		amClickedFAQ(getNodeText(this.props.top))

		if (is_closed && has_open_click) {
			this.props.onOpenClick()
		}
		if (is_open && has_close_click) {
			this.props.onCloseClick()
		}
	}

	render() {
		const { top, children, arrow, topWidth, className, role } = this.props
		const chevron = this.props.chevronSrc ?? 'https://eightsleep.imgix.net/chevron-down.svg?v=1633019879'
		return (
			<div
				className={className}
				role={role}
				aria-live={'polite'}
			>
				<div
					className={'legacy_box'}
					style={{
						backgroundColor: 'inherit',
						color: 'inherit',
					}}
				>
					<h3 className={styles.accordion_header}>
						<button
							onClick={() => this.handleClick()}
							className={styles.top}
							aria-expanded={this.state.open}
							aria-controls={this.state.id}
							id={`accordion-button-for-${this.state.id}`}
						>
							{top}
							<img
								className={`${this.state.open ? 'arrow_slide_down_up' : 'arrow_slide_down_down'} ${this.props.inverted && 'inverted'}`}
								src={chevron}
								alt={this.state.open ? 'close' : 'expand'}
							/>
						</button>
					</h3>
				</div>
				<AnimateHeight
					duration={this.props.animationDuration || 100}
					height={this.state.open ? 'auto' : 0}
				>
					<div
						id={this.state.id}
						aria-labelledby={`accordion-button-for-${this.state.id}`}
					>
						{children}
					</div>
				</AnimateHeight>
			</div>
		)
	}
}
